(function () {

    const rubbishDetails = document.querySelectorAll('.js-rubbish-details');
    const modules = document.querySelector('.js-rr-modules');

    // Letter click search
    const letters = document.querySelectorAll('.js-search-letter');
    const letterSelected = document.querySelector('.js-letter-selected');
    const letterFilter = document.querySelector('.js-letter-filter');
    const letterFilterItems = document.querySelectorAll('.js-letter-filter-item');

    letters.forEach(letter => {
        letter.addEventListener("click", e => {
            selectLetter(e.target)
        })
        letter.addEventListener("keypress", e => {
            if (e.key === "Enter") {
                selectLetter(e.target)
            }
        })
    })

    letterFilterItems.forEach(filterItem => {
        filterItem.addEventListener('click', () => {
            selectLetterItem(filterItem)
        })
        filterItem.addEventListener('keypress', e => {
            if (e.key === "Enter") {
                selectLetterItem(filterItem)
            }
        })
    })

    function selectLetterItem(filterItem) {
        rubbishDetails.forEach(item => {
            item.classList.remove('c-rr__item--active');
            if (item.dataset.name == filterItem.innerHTML) {
                hideLetterFilter()
                hideModules()
                hideDropdown()
                hideSearchInput()
                item.classList.add('c-rr__item--active');
            }
        })
        searchInput.value = "";
    }

    function selectLetter(letter) {
        const selectedLetter = letter.innerHTML
        showLetterFilter()
        letterSelected.innerHTML = selectedLetter;
        letters.forEach(x => {
            x.classList.remove('c-rr__search-letter--selected')
        })
        letter.classList.add('c-rr__search-letter--selected')
        letterFilterItems.forEach(filterItem => {
            filterItem.classList.remove('c-rr__filter-item--active')
            if (filterItem.innerHTML.charAt(0) == selectedLetter) {
                hideDropdown()
                hideSearchInput()
                filterItem.classList.add('c-rr__filter-item--active')
            }
        })
    }


    // Text input search
    const searchBox = document.querySelector('.js-search-box');
    const searchInput = document.querySelector('.js-search-input');
    //const searchInputButton = document.querySelector('.js-search-button');
    const dropdownResults = document.querySelector('.js-search-input-results');
    const filteredDropdown = dropdownResults != null && dropdownResults.querySelectorAll('.js-search-input-result');

    if (searchInput != null) {
        searchInput.addEventListener("keyup", e => {
            const input = e.target.value;
            if (input.length > 2) {
                autoCompleteSearch(input)
                showDropdown()
                showSearchInput()
            }
            else {
                hideDropdown()
                hideSearchInput()
            }
        })
    }

    //searchInputButton.addEventListener("click", () => {
    //    const input = searchInput.value;
    //    autoCompleteSearch(input);
    //})

    if (searchBox != null) {
        searchBox.addEventListener('click', () => {
            hideDropdown()
            hideSearchInput()
        });
    }

    if (filteredDropdown != null && filteredDropdown.length > 0) {
        filteredDropdown.forEach(dropdownItem => {
            dropdownItem.addEventListener("click", () => {
                selectDropdownItem(dropdownItem)
            })
            dropdownItem.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    selectDropdownItem(dropdownItem)
                }
            })
        })
    }

    function selectDropdownItem(dropdownItem) {
        hideDropdown()
        hideSearchInput()
        hideLetterFilter()
        searchInput.value = dropdownItem.innerHTML;
        rubbishDetails.forEach(item => {
            item.classList.remove('c-rr__item--active');
            if (item.dataset.name == dropdownItem.innerHTML) {
                item.classList.add('c-rr__item--active');
                hideModules()
            }
        })
    }

    function autoCompleteSearch(input) {
        filteredDropdown.forEach(dropdownItem => {
            if (dropdownItem.innerHTML.toLowerCase().includes(input.toLowerCase())) {
                dropdownItem.classList.add('c-rr__search-input-result--active')
            }
            else {
                dropdownItem.classList.remove('c-rr__search-input-result--active')
            }
        })
    }



    // Show/Hide
    function hideDropdown() {
        dropdownResults.classList.remove('c-rr__search-input-results--open')
        searchBox.classList.remove('c-rr__search-box--active')
    }
    function showDropdown() {
        dropdownResults.classList.add('c-rr__search-input-results--open')
        searchBox.classList.add('c-rr__search-box--active')
    }
    function hideSearchInput() {
        searchInput.classList.remove('c-rr__search-input--active')
    }
    function showSearchInput() {
        searchInput.classList.add('c-rr__search-input--active')
    }
    function hideLetterFilter() {
        letterFilter.classList.remove('c-rr__letter-filter--show')
    }
    function showLetterFilter() {
        letterFilter.classList.add('c-rr__letter-filter--show')
    }
    function hideModules() {
        modules.classList.add('c-rr__modules--hide')
    }
    function showModules() {
        modules.classList.remove('c-rr__modules--hide')
    }



})();
